import "./App.css";
import { Outlet } from "react-router-dom";
import {
  Header,
  Footer,
} from "./Theme/Pages/components";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
