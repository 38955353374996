import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Testimonial() {
  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="text-center">
          <h6 className="text-secondary text-uppercase">Testimonial</h6>
          <h1 className="mb-5">Our Clients Say!</h1>
        </div>
        <OwlCarousel
          className="owl-theme owl-carousel testimonial-carousel position-relative wow fadeInUp"
          loop={true}
          margin={10}
          nav={true}
          items={3}
          mouseDrag={true}
          autoplay={true}
          autoplayTimeout={3000}
          autoplayHoverPause={true}
          smartSpeed={1500}
          dots={false}
          navText={[
            '<i class="bi bi-chevron-left"></i>',
            '<i class="bi bi-chevron-right"></i>',
          ]}>
          <div className="testimonial-item text-center">
            <div className="testimonial-text bg-light text-center p-4 mb-4">
              <p className="mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <img
              className="bg-light rounded-circle p-2 mx-auto mb-2"
              src={"img/testimonial-1.jpg"}
              style={{ width: "80px", height: "80px" }}
              alt={""}
            />
            <div className="mb-2">
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
            </div>
            <h5 className="mb-1">Client Name</h5>
            <p className="m-0">Profession</p>
          </div>
          <div className="testimonial-item text-center">
            <div className="testimonial-text bg-light text-center p-4 mb-4">
              <p className="mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <img
              className="bg-light rounded-circle p-2 mx-auto mb-2"
              src={"img/testimonial-2.jpg"}
              style={{ width: "80px", height: "80px" }}
              alt={""}
            />
            <div className="mb-2">
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
            </div>
            <h5 className="mb-1">Client Name</h5>
            <p className="m-0">Profession</p>
          </div>
          <div className="testimonial-item text-center">
            <div className="testimonial-text bg-light text-center p-4 mb-4">
              <p className="mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <img
              className="bg-light rounded-circle p-2 mx-auto mb-2"
              src={"img/testimonial-3.jpg"}
              style={{ width: "80px", height: "80px" }}
              alt={""}
            />
            <div className="mb-2">
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
            </div>
            <h5 className="mb-1">Client Name</h5>
            <p className="m-0">Profession</p>
          </div>
          <div className="testimonial-item text-center">
            <div className="testimonial-text bg-light text-center p-4 mb-4">
              <p className="mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <img
              className="bg-light rounded-circle p-2 mx-auto mb-2"
              src={"img/testimonial-4.jpg"}
              style={{ width: "80px", height: "80px" }}
              alt={""}
            />
            <div className="mb-2">
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
              <small className="fa fa-star text-secondary"></small>
            </div>
            <h5 className="mb-1">Client Name</h5>
            <p className="m-0">Profession</p>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
}
