import React from "react";
import {
  Carousel,
  About,
  Booking,
  Fact,
  Service,
  ServiceTwo,
} from "./components";

export default function Home() {
  return (
    <>
      <Carousel></Carousel>
      <About />
      <Fact />
      <Service />
      <ServiceTwo />
      <Booking />
    </>
  );
}
