import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function ServiceTwo() {
  return (
    <div className="container-fluid py-5 px-4 px-lg-0">
      <div className="row g-0">
        <div className="col-lg-3 d-none d-lg-flex">
          <div className="d-flex align-items-center justify-content-center bg-primary w-100 h-100">
            <h1
              className="display-3 text-white m-0"
              //style="transform: rotate(-90deg);"
            >
              15 Years Experience
            </h1>
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="ms-lg-5 ps-lg-5">
            <div
              className="text-center text-lg-start wow fadeInUp"
              data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase">Our Services</h6>
              <h1 className="mb-5">Explore Our Services</h1>
            </div>
            <OwlCarousel
              className="owl-theme owl-carousel service-carousel position-relative wow fadeInUp"
              loop={true}
              margin={10}
              nav={true}
              items={3}
              mouseDrag={true}
              autoplay={true}
              autoplayTimeout={3000}
              autoplayHoverPause={true}
              smartSpeed={1500}
              dots={false}
              navText={[
                '<i class="bi bi-chevron-left"></i>',
                '<i class="bi bi-chevron-right"></i>',
              ]}>
              {/* <div
              
              data-wow-delay="0.1s"> */}
              <div className="bg-light p-4">
                <div
                  className="d-flex align-items-center justify-content-center border border-5 border-white mb-4"
                  //style="width: 75px; height: 75px;"
                >
                  <i className="fa fa-water fa-2x text-primary"></i>
                </div>
                <h4 className="mb-3">Drain Repair</h4>
                <p>
                  Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam
                  lorem diam justo.
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Quality
                  Service
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Customer
                  Satisfaction
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Support 24/7
                </p>
                <a href="" className="btn bg-white text-primary w-100 mt-2">
                  Read More
                  <i className="fa fa-arrow-right text-secondary ms-2"></i>
                </a>
              </div>
              <div className="bg-light p-4">
                <div
                  className="d-flex align-items-center justify-content-center border border-5 border-white mb-4"
                  //style="width: 75px; height: 75px;"
                >
                  <i className="fa fa-toilet fa-2x text-primary"></i>
                </div>
                <h4 className="mb-3">Toilet Pipe Repair</h4>
                <p>
                  Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam
                  lorem diam justo.
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Quality
                  Service
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Customer
                  Satisfaction
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Support 24/7
                </p>
                <a href="" className="btn bg-white text-primary w-100 mt-2">
                  Read More
                  <i className="fa fa-arrow-right text-secondary ms-2"></i>
                </a>
              </div>
              <div className="bg-light p-4">
                <div
                  className="d-flex align-items-center justify-content-center border border-5 border-white mb-4"
                  //style="width: 75px; height: 75px;"
                >
                  <i className="fa fa-shower fa-2x text-primary"></i>
                </div>
                <h4 className="mb-3">Sewer Line Repair</h4>
                <p>
                  Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam
                  lorem diam justo.
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Quality
                  Service
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Customer
                  Satisfaction
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Support 24/7
                </p>
                <a href="" className="btn bg-white text-primary w-100 mt-2">
                  Read More
                  <i className="fa fa-arrow-right text-secondary ms-2"></i>
                </a>
              </div>
              <div className="bg-light p-4">
                <div
                  className="d-flex align-items-center justify-content-center border border-5 border-white mb-4"
                  //style="width: 75px; height: 75px;"
                >
                  <i className="fa fa-tint fa-2x text-primary"></i>
                </div>
                <h4 className="mb-3">Water Heater Repair</h4>
                <p>
                  Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam
                  lorem diam justo.
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Quality
                  Service
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Customer
                  Satisfaction
                </p>
                <p className="text-primary fw-medium">
                  <i className="fa fa-check text-success me-2"></i>Support 24/7
                </p>
                <a href="" className="btn bg-white text-primary w-100 mt-2">
                  Read More
                  <i className="fa fa-arrow-right text-secondary ms-2"></i>
                </a>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}
