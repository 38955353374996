import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Carousel() {
  return (
    <div className="container-fluid p-0 mb-5 header-carousel">
      <OwlCarousel className='owl-theme' 
                   loop={true} 
                   margin={10} 
                   nav={true}
                   items={1}
                   mouseDrag={true}
                   autoplay={true}
                   autoplayTimeout={3000}
                   autoplayHoverPause={true}
                   smartSpeed={1500}
                   dots={false}
                   navText={[
                    '<i class="bi bi-chevron-left"></i>',
                    '<i class="bi bi-chevron-right"></i>',
                  ]}
      >
        <div className="item position-relative">
          <img className="img-fluid" src="img/carousel-1.jpg" alt="" />
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center carouselContent">
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Plumbing & Repairing Services
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                    Professional Residential Plumbing Services
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Vero elitr justo clita lorem. Ipsum dolor at sed stet sit
                    diam no. Kasd rebum ipsum et diam justo clita et kasd rebum
                    sea elitr.
                  </p>
                  <a
                    href=""
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">
                    Read More
                  </a>
                  <a
                    href=""
                    className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">
                    Free Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item position-relative">
          <img className="img-fluid" src="img/carousel-2.jpg" alt="" />
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center carouselContent">
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Plumbing & Repairing Services
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                  Professional Commercial Plumbing Services
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Vero elitr justo clita lorem. Ipsum dolor at sed stet sit
                    diam no. Kasd rebum ipsum et diam justo clita et kasd rebum
                    sea elitr.
                  </p>
                  <a
                    href=""
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">
                    Read More
                  </a>
                  <a
                    href=""
                    className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">
                    Free Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
}
