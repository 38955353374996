import React from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav me-auto">
        <Link to="/" className="nav-item nav-link active">
          Home
        </Link>
        <Link to="/about" className="nav-item nav-link">
          About
        </Link>
        <Link to="/services" className="nav-item nav-link">
          Services
        </Link>

        <div className="nav-item dropdown">
          <a
            href="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown">
            Pages
          </a>
          <div className="dropdown-menu fade-up m-0">
            <Link to="/booking" className="dropdown-item">
              Booking
            </Link>
            <Link to="/team" className="dropdown-item">
              Technicians
            </Link>
            <Link to="/testimonial" className="dropdown-item">
              Testimonial
            </Link>
          </div>
        </div>
        <Link to="/contact" className="nav-item nav-link">
          Contact
        </Link>
      </div>
      <div className="mt-4 mt-lg-0 me-lg-n4 py-3 px-4 bg-primary d-flex align-items-center">
        <div
          className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
          style={{ width: "45px", height: "45px" }}>
          <i className="fa fa-phone-alt text-primary"></i>
        </div>
        <div className="ms-3">
          <p className="mb-1 text-white">Emergency 24 / 7 / 356</p>
          <h5 className="m-0 text-secondary">+012 345 6789</h5>
        </div>
      </div>
    </div>
  );
}
